import React from 'react';
import { graphql } from 'gatsby';
import yaml from 'js-yaml';

import BaseLayout from '../components/layout/base-layout';
import { BasicColumns, Column } from '../components/layout/basic-columns';
import BigButton from '../components/common/big-button';
import BookList from '../components/books/list';
import DiscountsCallout from '../components/homepage/discounts-callout';
import NewsletterSignup from '../components/common/newsletter-signup';
import PageColumn from '../components/layout/page-column';
import RainbowBar from '../components/common/rainbow-bar';
import Section from '../components/layout/section';
import SectionHeader from '../components/common/section-header';
import TaxonomyGrid from '../components/common/taxonomy/grid';

export default (props) => {
  const bookNodes = props.data.books.edges.map(({ node }) => node);
  const categoryNodes = props.data.categories.edges.map(({ node }) => node);

  let discountBookNodes = props.data.discountedBooks.edges.map(({ node }) => node);
  discountBookNodes = discountBookNodes.sort((a, b) => {
    const aHistory = yaml.load(a.data.Price_History);
    const bHistory = yaml.load(b.data.Price_History);

    const aDatestamp = aHistory[aHistory.length - 1].timestamp;
    const bDatestamp = bHistory[bHistory.length - 1].timestamp;

    if (bDatestamp > aDatestamp) {
      return 1;
    } else if (aDatestamp > bDatestamp) {
      return -1;
    }

    return 0;
  }).slice(0, 5);

  const selectedCategoryNodes = categoryNodes.sort((a, b) => {
    if (!a.data.Books) return 1;
    if (!b.data.Books) return -1;

    return b.data.Books.length - a.data.Books.length
  }).slice(0,9);

  return (
    <BaseLayout homepage={ true }>
      <Section color="light-gray" topSpacing="section" bottomSpacing="section">
        <PageColumn>
          <BasicColumns>
            <Column>
              <DiscountsCallout bookNodes={ discountBookNodes } />
            </Column>
            <Column>
              <NewsletterSignup />
            </Column>
          </BasicColumns>
        </PageColumn>
      </Section>

      <RainbowBar width="thin" />
      <Section topSpacing="section">
        <PageColumn>
          <SectionHeader label="Recently Updated Cookbooks" />
          <BookList bookNodes={ bookNodes } />
        </PageColumn>
      </Section>
      <Section topSpacing="subsection" bottomSpacing="section">
        <BigButton
          to="/books"
          label="More Cookbooks"
        />
      </Section>

      <RainbowBar width={'thin'} />
      <Section topSpacing="section" color="light-gray">
        <PageColumn>
          <SectionHeader label="Cookbooks by Category" />
          <TaxonomyGrid
            nodes={ selectedCategoryNodes }
            labelProperty="Label"
            pathStem="/category"
          />
        </PageColumn>
      </Section>
      <Section topSpacing="subsection" bottomSpacing="section" color="light-gray">
        <PageColumn>
          <BigButton
            to="/categories"
            label="More Categories"
          />
        </PageColumn>
      </Section>
    </BaseLayout>
  )
};

export const query = graphql`
{
  discountedBooks:allAirtable(filter:{table:{eq:"Books"}, data:{Published:{eq:true}, Kindle_Price:{lt:5}}},
              sort:{fields: data___Last_Updated, order:DESC}) {
    edges {
      node {
        data {
          ...BookForListing
        }
      }
    }
  }
  books:allAirtable(filter:{table:{eq:"Books"}, data:{Published:{eq:true}}},
              sort:{fields: data___Last_Updated, order:DESC},
              limit: 12) {
    edges {
      node {
        data {
          ...BookForListing
        }
      }
    }
  }
  categories:allAirtable(filter:{table:{eq:"Categories"}},
                  sort:{fields: data___Label, order:ASC}) {
    edges {
      node {
        data {
          Label
          Slug
          Books {
            data {
              Slug
            }
          }
        }
      }
    }
  }
}
`;
