import React from 'react';

import '../../styles/layouts/basic-columns.scss';

export const BasicColumns = (props) => (
  <div className="basic-columns">
    { props.children }
  </div>
);

export const Column = (props) => (
  <div className="column">
    { props.children }
  </div>
);