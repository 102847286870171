import React from 'react';

import BookListItem from './list-item';

import '../../styles/books/list.scss';

export default (props) => {
  const bookNodes = props.bookNodes;

  if (!bookNodes) {
    return null;
  }

  return (
    <ul className="book-list">
      {bookNodes.map(({ data }) => (
        <BookListItem
          key={ data.Slug }
          data={ data }
        />
      ))}
    </ul>
  );
};