import React from 'react';
import { Link } from 'gatsby';

import '../../styles/common/big-button.scss';

export default (props) => (
  <div className="big-button-wrapper">
    <Link
      to={ props.to }
      className="big-button"
    >
      { props.label }
    </Link>
  </div>
);