import React from 'react';
import { Link } from 'gatsby';

import Byline from '../authors/byline';
import CategoryBar from '../categories/bar';
import { DiscountSvg } from '../common/svg';

import { 
  amazonUrlFromAsin,
  formattedBookTitle,
} from '../../helpers/books';

export default (props) => {
  const data = props.data;

  return (
    <li>
      <Link
        to={`/book/${data.Slug}`}
        className="book-list__cover"
      >
        <div className="book-list__cover__image-wrapper">
          <img src={ data.Signed_Cover_URL } alt="Cover" />
        </div>
      </Link>
      <div className="book-list__title">
        <Link to={`/book/${data.Slug}`}>
          {formattedBookTitle(data.Title, data.Subtitle)}
        </Link>
      </div>
      {(data.Authors) ? (
        <div className="book-list__byline">
          <Byline
            authorNodes={ data.Authors }
            orderedAuthors={ data.Ordered_Authors }
          />
        </div>
      ) : null}
      {(data.Categories) ? (
        <div className="book-list__categories">
          <CategoryBar
            categoryNodes={ data.Categories }
          />
        </div>
      ) : null}
      <div className="book-list__icons">
        {(data.Kindle_Price < 5) ? (
          <a href={amazonUrlFromAsin(data.Kindle_ASIN)}>
            { DiscountSvg }
          </a>
        ) : null}
      </div>
    </li>
  );
};