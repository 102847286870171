import React from 'react';
import { Link } from 'gatsby';

import Byline from '../authors/byline';
import { DiscountSvg } from '../common/svg';
import FileCard from '../common/file-card';

import {
  formattedBookTitle,
} from '../../helpers/books';

import '../../styles/homepage/discounts-callout.scss';

export default (props) => {
  return (
    <FileCard
      icon={ DiscountSvg }
      label="Discounted for Amazon Kindle"
    >
      <div className="discounts-callout">
        <ul>
          {props.bookNodes.map((node, idx) => {
            const bookData = node.data;

            return (
              <li key={`discountedBook-${idx}`}>
                <Link to={`/book/${bookData.Slug}`}>
                  <div className="price">
                    <span className="currency">$</span>{ bookData.Kindle_Price }
                  </div>
                  <div className="book-details">
                    <span className="title">{ formattedBookTitle(bookData.Title, bookData.Subtitle) }</span>
                    <span className="byline">
                      <Byline
                        authorNodes={ bookData.Authors }
                        orderedAuthors={ bookData.Ordered_Authors }
                        suppressLinks={ true }
                      />
                    </span>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
        <div className="more-button">
          <Link to="/kindle-discounts">More Kindle Discounts</Link>
        </div>
      </div>
    </FileCard>
  );
};