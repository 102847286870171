import React from 'react';

import '../../styles/common/file-card.scss';

export default (props) => (
  <div className="file-card">
    <h3>
      <span className="icon">
        { props.icon }
      </span>
      <span className="tag">
        { props.label }
      </span>
    </h3>
    <div className="file-card__contents">
      { props.children }
    </div>
  </div>
);