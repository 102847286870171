import React from 'react';

import { EnvelopeSvg } from '../common/svg';
import FileCard from '../common/file-card';

import '../../styles/common/newsletter-signup.scss';

export default (props) => {
  return (
    <FileCard
      icon={ EnvelopeSvg }
      label="Get Updates"
    >
      <div className="newsletter-signup">
        <p>
          We’re busy building <b>Cookbooking</b>. Sign up for our newsletter to stay up-to-date 
          with new content and features.
        </p>
        <form
          action="https://buttondown.email/api/emails/embed-subscribe/cookbooking"
          method="post"
          target="popupwindow"
          onSubmit={ () => window.open('https://buttondown.email/cookbooking', 'popupwindow') }
          className="embeddable-buttondown-form"
        >
          <input type="email" name="email" id="bd-email" placeholder="Enter Your Email…" />
          <input type="hidden" value="1" name="embed"></input>
          <input type="submit" value="Subscribe"></input>
        </form>
      </div>
    </FileCard>
  );
}